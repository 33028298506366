<template>
    <div class="app_container">
      
      <div class="week_bg_2 margin-top"  style="background-image: url(img/weekLuckyMan/top_bg_2.png);">
        <div class="go_back_button" style="background-image: url(img/weekLuckyMan/go_back.png);" @click="goBack"></div>
        <!-- <div class="st_share_button" style="background-image: url(img/share_button.png);" @click="goShare"></div> -->
        <div class="main_box">
            <div class="active_title4" style="background-image: url(img/weekLuckyMan/lucky_people.png);margin-top: 0;"></div>
        <div class="slide_list" style="background-image: url(img/weekLuckyMan/rank_bg_2.png);">
          <div class="w_noData" v-if="noData">
            <img src="img/weekLuckyMan/no_data.png" alt="">
          </div>
          <vue-seamless-scroll v-if="listData.length>0" :data="listData" class="warp">
            <div class="gift_item"
              :style="item.rank=='3'?'background-image: url(img/weekLuckyMan/level_three.png);':(item.rank=='2'?'background-image: url(img/weekLuckyMan/level_two.png);':'background-image: url(img/weekLuckyMan/level_one.png);')"
              v-for="(item,index) in listData" :key="index">
              <div class="gift_item_top">
                <span class="nick_name">{{item.nickname}}</span>
                <span
                  class="gift_name">{{item.gift_name=='金币'?(item.gift_name+item.diamonds+'X'+item.num):item.gift_name+'X'+item.num}}</span>
              </div>
              <div class="gift_item_mid"></div>
              <div class="gift_item_bottom">
                <span class="user_id">ID:{{item.uid}}</span>
                <span class="gift_date">{{item.created_at}}</span>
              </div>
            </div>
          </vue-seamless-scroll>
  
        </div>
      </div>
      <div class="bottom_bg" style="background-image: url(img/weekLuckyMan/bottom_tip.png);"></div>
        </div>
    
      <!-- <div class="mask" v-if="showMask">
        <div class="gift_box_share" style="background-image: url(img/share_bg.png);">
          <div class="btn-container">
            <div class="wechat" style="background-image: url(img/wechat_bg.png);" @click="shareToWechat"></div>
            <div class="firend_circle" style="background-image: url(img/firend_circle.png);" @click="shareToTimeline">
            </div>
          </div>
          <div class="ok_btn" style="background-image: url(img/ok.png);" @click="closeMask"></div>
        </div>
      </div> -->
    </div>
  
  </template>
     
  <script>
  import "@/assets/css/weekLuckyMan.css"
  import vueSeamlessScroll from 'vue-seamless-scroll'
  import { luckyMan} from "@/api/ranking";
  export default {
    name: "lotteryPeople",
    data() {
      return {
        noData: true,
        listData: []
      };
    },
  
    components: {
      vueSeamlessScroll
    },
    created() {
      this.getRecord()
    },
  
    watch: {},
  
    mounted() { },
  
    methods: {
  
      getRecord() {
        luckyMan({}).then(response => {
          if (response.data.length == 0) {
            this.noData = true
          } else {
            this.noData = false
            this.listData = response.data
            console.log(this.listData)
          }
        },
          error => {
            this.noData = true
            // this.$toast.fail(error.msg)
          }).catch((error) => {
            this.noData = true
            // this.$toast.fail(error.msg)
          })
      },
      goBack() {
        this.$router.go(-1)
      },
    //   goShare() {
    //     this.showMask = true
    //     share({}).then(res => {
    //       console.log(res)
    //     })
    //   },
      // closeMask() {
      //   this.showMask = false
      // }
    },
  
    computed: {}
  };
  </script>
     
     